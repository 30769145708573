//index.js

import React from 'react';
import ReactDOM from 'react-dom/client'; // Use the new API
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement); // Create a root

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
