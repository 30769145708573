import React, { useState } from 'react';

const CustomDropdown = ({ filter, handleFilterChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (value) => {
    handleFilterChange({ target: { value } });
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <div className="filter-container">
      <label htmlFor="filter" onClick={toggleDropdown}>
        Filter by: 
      </label>
      <div className={`dropdown ${isDropdownOpen ? 'open' : ''}`}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          {filter || 'Select'}
        </div>
        {isDropdownOpen && (
          <ul className="dropdown-list">
            <li onClick={() => handleOptionClick('name')}>Name</li>
            <li onClick={() => handleOptionClick('recent')}>Most Recent</li>
            <li onClick={() => handleOptionClick('company')}>Company</li>
            <li onClick={() => handleOptionClick('flagged')}>Flagged</li> 
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
