import React, { useState } from 'react';
import axios from 'axios';
import '../styles/ForgotPasswordPage.css';
import config from '../config';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/auth/forgot-password`, { email });
      setMessage(response.data.message);
      setError('');
    } catch (error) {
      setError('Failed to send reset link. Please try again.');
      setMessage('');
    }
  };

  return (
    <div className="forgot-password-container">
      <video autoPlay loop muted playsInline className="backgroundVideo">
      <source
        src="https://velox-network-login-video.s3.eu-west-2.amazonaws.com/LoginVideo.mp4"  // Replace with your S3 URL
        type="video/mp4"
      />
        Your browser does not support the video tag.
      </video>
      <h2 className="forgot-password-title">Forgot Your Password?</h2>
      {message && <p className="forgot-password-success">{message}</p>}
      {error && <p className="forgot-password-error">{error}</p>}
      <form className="forgot-password-form" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          className="forgot-password-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="forgot-password-button">Send Reset Link</button>
      </form>
    </div>
  );
};

export default ForgotPasswordPage;
