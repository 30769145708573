import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import L from 'leaflet';
import '../styles/MapPage.css'; // Ensure this path is correct
import config from '../config';

// Fix for missing marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapPage = () => {
  const [connections, setConnections] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    const fetchConnections = async () => {
      const token = localStorage.getItem('token'); // Get token from localStorage

      if (!token) {
        console.error('No token found, user needs to login');
        navigate('/login'); // Redirect to login if no token is found
        return;
      }

      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/contact/mapContacts`, {
          headers: { Authorization: `Bearer ${token}` }, // Add token to the request
        });
        console.log('API response:', response.data); // Check the API response
        setConnections(response.data);
      } catch (error) {
        console.error('Error fetching connections:', error);
      }
    };

    fetchConnections();
  }, [navigate]);

  const handleBackToContacts = () => {
    navigate('/contacts');
  };

  return (
    <div className="map-page-container">
      {/*<h2 className="MapTitle">Map</h2>*/}
      <MapContainer center={[51.505, -0.09]} zoom={13} className="leaflet-container">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {connections.filter(conn => conn.lat && conn.lng).map((connection, index) => (
          <Marker key={index} position={[connection.lat, connection.lng]}>
            <Popup>
              You connected with {connection.name} here on {new Date(connection.dateAdded).toLocaleDateString()}.
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <button className="back-to-contacts-button" onClick={handleBackToContacts}>
        Return to Contacts
      </button>
    </div>
  );
};

export default MapPage;
