import React, { useEffect, useState } from 'react';
import { useParams, useNavigate/*, useLocation*/ } from 'react-router-dom';
import axios from 'axios';
import '../styles/DisplayCardPage.css';
import config from '../config';

const DisplayCardPage = () => {
  const { nfcUID } = useParams();
  const [cardInfo, setCardInfo] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  //const location = useLocation(); // To track the previous location


  useEffect(() => {
    const fetchCardInfo = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/nfc/${nfcUID}`);
        setCardInfo(response.data.cardInfo);

        // Get the logged-in user's information
        const token = localStorage.getItem('token');
        if (token) {
          const userResponse = await axios.get(`${config.apiBaseUrl}/api/user/account`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          const loggedInUserId = userResponse.data._id;

          
          // Check if the logged-in user is the owner of the card being viewed
          setIsOwner(loggedInUserId === response.data.cardInfo.userId);
        }
      } catch (error) {
        console.error('Error fetching card info:', error);
        setError('Failed to fetch card information.');
      }
    };

    fetchCardInfo();
  }, [nfcUID]);

  const handleSaveContact = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login', { state: { returnUrl: window.location.pathname } });
      return;
    }

    if (!token) {
      navigate('/register', { state: { returnUrl: window.location.pathname } });
      return;
    }

    const saveContact = async (latitude, longitude) => {
      try {
        const payload = { 
          nfcUID,
          name: cardInfo.name,
          email: cardInfo.email,
          phone: cardInfo.phone,
          company: cardInfo.company,
          role: cardInfo.role,
          linkedinURL: cardInfo.linkedinURL,
          additionalURL: cardInfo.additionalURL,
          pronouns: cardInfo.pronouns,
          ...(latitude && longitude && { lat: latitude, lng: longitude }) // Include lat/lng if available
        };

        const response = await axios.post(`${config.apiBaseUrl}/api/contact/create`, 
          payload, 
          { headers: { Authorization: `Bearer ${token}` } } 
        );

        if (response.status === 201) {
          alert('Contact saved successfully!');
          navigate('/contacts');
        }
      } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data.error === 'Contact already exists') {
          alert('This contact is already saved in your contacts.');
        } else {
          console.error('Error saving contact:', error.response?.data || error.message);
          alert(`Failed to save contact: ${error.response?.data.error || error.message}`);
        }
      }
    };

    // Try to get the user's location if available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          saveContact(latitude, longitude); // Save contact with location
        },
        (error) => {
          console.error('Error getting location:', error);
          saveContact(); // Save contact without location if geolocation fails
        }
      );
    } else {
      saveContact(); // Save contact without location if geolocation is not supported
    }
  };
/*
  const handleBackClick = () => {
    navigate('/my-card');
  };*/

  if (!cardInfo) {
    return <p>Loading card information...</p>;
  }

  return (
    <div className="display-card-page">
      <div className="business-card">
        <p>{cardInfo.pronouns || 'Pronouns not available'}</p>
        <p>{cardInfo.name || 'Name not available'}</p>
        <p>{cardInfo.company || 'Company not available'}</p>
        <p>{cardInfo.role || 'Role not available'}</p>
        <p>{cardInfo.email || 'Email not available'}</p>
        <p>{cardInfo.phone || 'Phone not available'}</p>
        <p>
          <a href={cardInfo.linkedinURL?.startsWith('http') ? cardInfo.linkedinURL : `https://${cardInfo.linkedinURL}`} target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
        </p>
        {cardInfo.additionalURL && (
          <p>
            <a href={cardInfo.additionalURL.startsWith('http') ? cardInfo.additionalURL : `https://${cardInfo.additionalURL}`} target="_blank" rel="noopener noreferrer">
              {cardInfo.additionalURL.length > 16
                ? `${cardInfo.additionalURL.slice(0, 16)}...`
                : cardInfo.additionalURL}
            </a>
          </p>
        )}
      </div>
      {!isOwner && ( // Show "Save Contact" button only if the logged-in user is not the owner
        <button type="button" className="save-contact-button" onClick={handleSaveContact}>
          Save Contact
        </button>
      )}
       {/*{isOwner && location.state?.fromMyCard && ( // Show "Back" button if the user is the owner and came from MyCardPage
        <button type="button" className="back-button" onClick={handleBackClick}>
          Return
        </button>
      )}*/}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default DisplayCardPage;
