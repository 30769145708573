import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/ResetPasswordPage.css'; 
import config from '../config';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/auth/reset-password/${token}`, { password });
      setMessage(response.data.message);
      setError('');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setError('Failed to reset password. Please try again.');
      setMessage('');
    }
  };

  return (
    <div className="reset-password-container">
      <video autoPlay loop muted playsInline className="backgroundVideo">
        <source src="/Videos/LoginVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h2 className="reset-password-title">Reset Your Password</h2>
      {message && <p className="reset-password-success">{message}</p>}
      {error && <p className="reset-password-error">{error}</p>}
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Enter new password"
          className="reset-password-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="reset-password-button">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
