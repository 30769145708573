/* App.js*/
import React, { useState, useEffect, useMemo } from 'react';
import { Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AccountPage from './pages/AccountPage';
import ContactsPage from './pages/ContactsPage';
import MapPage from './pages/MapPage';
//import EventPage from './pages/EventPage';
// import MessagesPage from './pages/MessagesPage';
//import ScanPage from './pages/ScanPage';
import MyCardPage from './pages/MyCardPage';
import DisplayCardPage from './pages/DisplayCardPage';
import './styles/App.css';
import './styles/Global.css'; 
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import DeleteAccountPage from './pages/DeleteAccountPage'; // Import the DeleteAccountPage component


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if the user is authenticated by looking for a token in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  // Paths where the navbar should be hidden
  const hideNavbarPaths = ['/login', '/register', '/forgot-password', '/reset-password', "/map"];

  // Determine if the current page is the login or register page
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

    // Memoize the navbar to prevent re-renders
    const navbar = useMemo(() => (
      <nav className="app-navbar">
        <Link to="/my-card" className={location.pathname === '/my-card' ? 'active' : ''}>My Card</Link>
        <Link to="/contacts" className={location.pathname === '/contacts' ? 'active' : ''}>Contacts</Link>
        <Link to="/account" className={location.pathname === '/account' ? 'active' : ''}>Account</Link>
      </nav>
    ), [location.pathname]);


  return (
    <div className="app-container"> 
      {/* Conditionally render the navbar based on authentication status and current path */}
      {!hideNavbarPaths.includes(location.pathname) && isAuthenticated && navbar}
    


    {/* delete 2 above and this works alone otherwise, what had origianlly
    <div className="app-container"> 
      {/* Conditionally render the navbar based on authentication status and current path 
      {!hideNavbarPaths.includes(location.pathname) && isAuthenticated && (
        <nav className="app-navbar">
          <Link to="/my-card" className={location.pathname === '/my-card' ? 'active' : ''}>My Card</Link>
          <Link to="/contacts" className={location.pathname === '/contacts' ? 'active' : ''}>Contacts</Link>
          <Link to="/account" className={location.pathname === '/account' ? 'active' : ''}>Account</Link>
        </nav>
      )}
      */}
<div className="app-content">
      <SwitchTransition>
        <CSSTransition
          key={location.key}
          classNames={isAuthPage ? 'fade' : 'slide'}
          timeout={500}
        >
                  <div>

          <Routes location={location}>
            {/* Auth routes*/}
            <Route path="/login" element={<LoginPage setAuth={setIsAuthenticated} />} />
            <Route path="/register" element={<RegisterPage />} />

            {/* Protected routes */}
            <Route path="/contacts" element={isAuthenticated ? <ContactsPage /> : <Navigate to="/login" />} />
            <Route path="/map" element={isAuthenticated ? <MapPage /> : <Navigate to="/login" />} />
            <Route path="/my-card" element={isAuthenticated ? <MyCardPage /> : <Navigate to="/login" />} />
      <Route path="/account" element={isAuthenticated ? <AccountPage /> : <Navigate to="/login" />} />
      <Route path="/delete-account" element={isAuthenticated ? <DeleteAccountPage /> : <Navigate to="/login" />} /> {/* Add the route for DeleteAccountPage */}


            {/* Unprotected routes */}
            <Route path="/my-display-card/:nfcUID" element={<DisplayCardPage />} />
            <Route path="/display-card/:nfcUID" element={<DisplayCardPage />} />
            <Route path="/nfc/:nfcUID" element={<DisplayCardPage />} /> {/* This page is accessible without login */}
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

          
            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
          </div>

        </CSSTransition>
      </SwitchTransition>
      </div>
    </div>
  );
};

export default App;

