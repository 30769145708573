/*LoginPage.js*/
import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/LoginPage.css'; // Ensure this path is correct and the CSS file exists
import config from '../config';


const LoginPage = ({ setAuth }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any existing error messages

    try {
      const res = await axios.post(
        `${config.apiBaseUrl}/api/auth/login`,
        { email, password },
        {
          headers: {
            'Content-Type': 'application/json', // Ensure correct content type
          },
          withCredentials: true, // Send cookies if needed
        }
      );
      //const res = await axios.post(`${config.apiBaseUrl}/api/auth/login`, { email, password });
      localStorage.setItem('token', res.data.token);
      setAuth(true);

      // Redirect to the intended page or default to the Contacts page
      const returnUrl = location.state?.returnUrl || '/contacts';
      navigate(returnUrl);
    } catch (error) {
      setError('Login failed. Please check your credentials and try again.');
      console.error('Login error:', error.response?.data || error.message);
    }
  };

  return (
    <div className="Logincontainer">
      <video autoPlay loop muted playsInline className="backgroundVideo">
      <source
        src="https://velox-network-login-video.s3.eu-west-2.amazonaws.com/LoginVideo.mp4"  // Replace with your S3 URL
        type="video/mp4"
      />
        {/*<source src="/Videos/LoginVideo.mp4" type="video/mp4" />*/}
        Your browser does not support the video tag.
      </video>
      <h1 className="loginTitle">Velox Network</h1>
      <h3 className="Sub-LoginTitle">Smarter Connections</h3>
      <div className="loginBox">
        <h2 className="LoginBoxHeading">Login</h2>
        {error && <p className="LogInError">{error}</p>}
        <form className="LogInForm" onSubmit={handleSubmit}>
          <input
            type="email"
            id="email" // Add a unique id
            name="email" // Add a unique name
            className="LogInFormInput"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            id="password" // Add a unique id
            name="password" // Add a unique name
            className="LogInFormInput"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className="LogInButton" type="submit">Login</button>
        </form>
        <p className="registerText">
          Don't have an account? <Link className="RegisterAccountButtonLink" to="/register">Register</Link>
        </p>
        <p className="forgotPasswordText">
          <Link className="forgot-password-link" to="/forgot-password">Forgot your password?</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
