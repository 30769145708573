// src/pages/DeleteAccountPage.js

import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/DeleteAccountPage.css';
import config from '../config';

const DeleteAccountPage = () => {
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${config.apiBaseUrl}/api/user/delete`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      localStorage.removeItem('token');
      alert('Your account has been deleted successfully.');
      navigate('/register');
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Failed to delete account. Please try again later.');
    }
  };

  const handleCancel = () => {
    navigate('/account');
  };

  return (
    <div className="delete-account-page">
      <h2>Confirm Account Deletion</h2>
      <p>Are you sure you want to delete your account? This action cannot be undone.</p>
      <button className="confirm-delete-account-button" onClick={handleDeleteAccount}>
        Yes, Delete My Account
      </button>
      <button className="cancel-delete-account-button" onClick={handleCancel}>
        Cancel
      </button>
    </div>
  );
};

export default DeleteAccountPage;
