/*RegisterPage.js*/
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import '../styles/RegisterPage.css';
import config from '../config';

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(''); // Added state for Date of Birth
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");

    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/auth/register`, {
        name,
        email,
        password,
        dateOfBirth, // Include Date of Birth in registration data
      });
      console.log("Registration successful:", response.data);
      alert('User registered successfully');
      /*navigate('/login');
    } catch (error) {
      console.error('Registration failed:', error.response ? error.response.data : error.message);
    }
  };
*/
 // Use returnUrl if available, otherwise fallback to default login
 const returnUrl = location.state?.returnUrl || '/login';
 navigate('/login', { state: { returnUrl } });
 /*
 const returnUrl = location.state?.returnUrl || '/login';
 navigate('/login', { state: { returnUrl: location.state?.returnUrl } });*/

 navigate(returnUrl);
} catch (error) {
 console.error('Registration failed:', error.response ? error.response.data : error.message);
}
};

  return (
    <div className="registerContainer">
      <video autoPlay loop muted playsInline className="backgroundVideo">
      <source
        src="https://velox-network-login-video.s3.eu-west-2.amazonaws.com/LoginVideo.mp4"  // Replace with your S3 URL
        type="video/mp4"
      />
        {/*<source src="/Videos/LoginVideo.mp4" type="video/mp4" />*/}
        Your browser does not support the video tag.
      </video>
      <h1 className="RegisterTitle">Join Us Today!</h1>
      <div className="registerBox">
        <h2 className="RegisterHeading">Register</h2>
        <form onSubmit={handleSubmit} className="Registerform">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="RegisterInput"
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="RegisterInput"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="RegisterInput"
            required
          />
          <input
            type="date"
            placeholder="Date of Birth"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            className="RegisterInput"
            required
          />
          <button type="submit" className="RegisterButton">Register</button>
          <Link to="/login" className="RegisterReturnToLoginButton">Back to Login</Link>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
