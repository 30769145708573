import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '../styles/MyCardPage.css';
import config from '../config';

const MyCardPage = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [businessInfo, setBusinessInfo] = useState({
        pronouns: '',
        name: '',
        company: '',
        role: '',
        email: '',
        phone: '',
        linkedinURL: '',
        additionalURL: '',
    });   

    const [nfcUID, setNfcUID] = useState(''); // State to store NFC UID
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        const fetchCardData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${config.apiBaseUrl}/api/user/account`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const userData = response.data;
                if (userData.businessCard) setBusinessInfo(userData.businessCard);
                if (userData.nfcUID) setNfcUID(userData.nfcUID);
            } catch (error) {
                console.error('Error fetching user card data:', error);
            }
        };

        fetchCardData();
    }, []);

    const handleBusinessChange = (e) => {
        setBusinessInfo({ ...businessInfo, [e.target.name]: e.target.value });
        setIsChanged(true); // Set `isChanged` to true whenever a field is updated
    };

    const handleSaveBusiness = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/user/updateBusinessCard`, businessInfo, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Business Info Saved:', response.data);
        } catch (error) {
            console.error('Error saving business info:', error);
        }
    };

    const handlePreview = () => {
        if (nfcUID) {
            /*navigate(`/nfc/${nfcUID}`); // Navigate to the DisplayCardPage with the user's nfcUID*/
            navigate(`/nfc/${nfcUID}`, { state: { fromMyCard: true } });
        } else {
            alert("You must link an NFC card to preview your card.");
        }
    };

    return (
        <div className="my-card-page">
            <h2>Edit Business Card</h2>
            <div className="my-card-container">
                <div className="edit-my-business-card">
                    {/*<h3>Business Information</h3>*/}
                    <form>
                        <label>
                            Pronouns:
                            <input
                                type="text"
                                name="pronouns"
                                value={businessInfo.pronouns || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>
                        <label>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={businessInfo.name || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>
                        <label>
                            Company:
                            <input
                                type="text"
                                name="company"
                                value={businessInfo.company || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>
                        <label>
                            Role:
                            <input
                                type="text"
                                name="role"
                                value={businessInfo.role || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>
                        <label>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={businessInfo.email || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>
                        <label>
                            Phone:
                            <input
                                type="tel"
                                name="phone"
                                value={businessInfo.phone || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>
                        <label>
                            LinkedIn URL:
                            <input
                                type="url"
                                name="linkedinURL"
                                value={businessInfo.linkedinURL || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>
                        <label>
                            Additional URL:
                            <input
                                type="url"
                                name="additionalURL"
                                value={businessInfo.additionalURL || ''}
                                onChange={handleBusinessChange}
                            />
                        </label>

                {/* Conditionally render the Save button */}
                {isChanged && (         
            <button type="button" className="save-business-button" onClick={handleSaveBusiness}>
                Save
            </button>
                                    )}
            </form>
                </div>
            </div>
            <button type="button" className="preview-button" onClick={handlePreview}>
                   Preview
            </button>
        </div>
    );
};

export default MyCardPage;
