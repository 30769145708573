// src/config.js

const config = {
    apiBaseUrl: process.env.NODE_ENV === 'production'
      ? 'https://backend.velox-network.com'
      : 'http://localhost:5000', // Use localhost during development
  };
  
  export default config;
  

  /*For Development below - backend will only be hosted on localhost 5000 and all i need to do is un hide below and hide above. 
  // src/config.js

const config = {
  apiBaseUrl: process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'              // Localhost URL for backend
    : 'https://backend.velox-network.com', // Production backend URL
};

export default config;
*/