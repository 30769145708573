//ContactsPage.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/ContactsPage.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import CustomDropdown from '../components/CustomDropdown'; // Import the custom dropdown component
import config from '../config';

// Fix for missing marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// New component to trigger map resizing and invalidation
const ResizeMapView = () => {
  const map = useMap();
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize(); // Fixes map center issue in smaller containers
      map.setView(map.getCenter(), map.getZoom()); // Re-center the map explicitly
    }, 200); // Small delay to ensure it's called after rendering
  }, [map]);

  return null;
};

// Component to update the map's view dynamically
const ChangeMapView = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [map, center, zoom]);
  return null;
};

const ContactsPage = () => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]); // Separate state for filtered contacts
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [hoveredContactId, setHoveredContactId] = useState(null);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('recent');
  const [showConfirmDelete, setShowConfirmDelete] = useState(null);
  const [flaggedContacts, setFlaggedContacts] = useState([]); // New state for flagged contacts
  const confirmDeleteRef = useRef(null);
  const [sendingCard, setSendingCard] = useState(false);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center for London
  const [mapZoom, setMapZoom] = useState(13); // Default zoom level
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate(); // Hook to handle navigation
    const [isEditing, setIsEditing] = useState(false);



   // Effect to handle window resize and update isMobile state
   useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handle navigation to the map page
  const handleMapClick = () => {
    if (isMobile) {
      navigate('/map'); // Redirect to the map page when on mobile
    }
  };


  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const res = await axios.get(`${config.apiBaseUrl}/api/contact`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        if (res.data && res.data.length > 0) {
          setContacts(res.data);
          setSelectedContactId(res.data[0]._id); // Select the first contact by default
          sortContacts('recent'); // Apply "Most Recent" sorting by default

                  // Fix: Explicitly set filteredContacts after sorting

          setFilteredContacts([...res.data].sort((a, b) => new Date(b.connectedAt) - new Date(a.connectedAt)));

        } else {
          setError('No contacts available.');
        }
      } catch (error) {
        console.error('Failed to fetch contacts', error);
        setError('Failed to fetch contacts. Please try again later.');
      }
    };

    fetchContacts();


    const handleClickOutside = (event) => {
      if (confirmDeleteRef.current && !confirmDeleteRef.current.contains(event.target)) {
        setShowConfirmDelete(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

  const toggleFlag = (id) => {
    setFlaggedContacts((prev) =>
      prev.includes(id) ? prev.filter((flaggedId) => flaggedId !== id) : [...prev, id]
    );
  };

const handleCardClick = (id) => {
  const contact = contacts.find(contact => contact._id === id);
  if (contact && contact.lat && contact.lng) {
    setMapCenter([contact.lat, contact.lng]); // Update the map center
    setMapZoom(15); // Zoom in to focus on the contact
  } else {
    setMapCenter([51.505, -0.09]); // Default to London if no location
    setMapZoom(13); // Reset zoom
  }
  setSelectedContactId(id);
  setHoveredContactId(null);
};

  const handleCardHover = (id) => {
    setHoveredContactId(id);
  };

  const handleCardLeave = () => {
    setHoveredContactId(null);
  };


    // Adjust handleFilterChange to reset the filtered contacts based on the criterion
    const handleFilterChange = (event) => {
      const criterion = event.target ? event.target.value : event;
      setFilter(criterion);
      sortContacts(criterion);
    };

  
  const sortContacts = (criterion) => {

    let sortedContacts = [...contacts];
    switch (criterion) {
      case 'name':
        sortedContacts.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'recent':
        /*sortedContacts.sort((a, b) => new Date(b.connectedAt) - new Date(a.connectedAt));*/
        sortedContacts.sort((a, b) => {
          const dateA = new Date(a.connectedAt || 0); // Fallback to 0 (oldest possible) if no date
          const dateB = new Date(b.connectedAt || 0);
          return dateB - dateA;
        });        
        break;
      case 'country':
        sortedContacts.sort((a, b) => a.country.localeCompare(b.country));
        break;
      case 'event':
        sortedContacts.sort((a, b) => a.event.localeCompare(b.event));
        break;
        case 'company': // New case for sorting by company
        sortedContacts.sort((a, b) => a.company.localeCompare(b.company));
        break;
        case 'flagged': 
        sortedContacts = contacts.filter(contact => flaggedContacts.includes(contact._id));
        break;
      default:
        sortedContacts = contacts; // Reset to all contacts when no specific filter is applied
        break;
      }
      setFilteredContacts(sortedContacts); // Update the filtered contacts
      };

/*
  const handleDelete = (id) => {
    axios
      .delete(`http://localhost:5000/api/contact/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(() => {
        setContacts(contacts.filter((contact) => contact._id !== id));
        setShowConfirmDelete(null);
      })
      .catch((err) => console.error('Failed to delete contact', err));
  };
*/
const handleDelete = (id) => {
  const confirmDeletion = window.confirm("Are you sure you want to delete this contact?");
  if (confirmDeletion) {
    axios
      .delete(`${config.apiBaseUrl}/api/contact/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(() => {
        setContacts(contacts.filter((contact) => contact._id !== id));
        setShowConfirmDelete(null);
        setIsEditing(false); // Deactivate edit mode after deletion
      })
      .catch((err) => console.error('Failed to delete contact', err));
  }
};
/*
  const handleConfirmDelete = (id) => {
    setShowConfirmDelete(id);
  };
*/
  const cancelDelete = () => {
    setShowConfirmDelete(null); // Hide the delete confirmation dialog
    setIsEditing(false); // Deactivate the edit mode
  };

  const handleEdit = () => {
  // Toggle edit mode and update button visibility
  setIsEditing((prev) => !prev); // Toggle the edit state
  setShowConfirmDelete(null); // Hide any open delete confirmation dialogs when toggling edit mode

};

    // Logic to open edit modal to tag business card to an event or edit details
       // NEW: Logic to open edit mode and reset button visibility 
       /*setIsEditing(true);
       setContacts((prevContacts) =>
         prevContacts.map((contact) => ({
           ...contact,
           contactDetailsSent: false, // Reset to show the envelope button
         }))
       );
     };*/
/*
  const handleAddContact = () => {
    // Logic to add a new contact manually
  };
*/


/*
  const handleSendCard = async (contactId) => {
    if (sendingCard) return;
    setSendingCard(true);

    try {
      const response = await axios.post(`http://localhost:5000/api/contact/send-card/${contactId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      alert(response.data.message);
 // Update the contact details locally to reflect that the card has been sent
 setContacts((prevContacts) =>
 prevContacts.map((contact) =>
   contact._id === contactId ? { ...contact, contactDetailsSent: true } : contact
 )
);
} catch (error) {
console.error('Failed to send business card:', error);
alert('Failed to send business card.');
} finally {
setSendingCard(false);
}
}; */

const handleSendCard = async (contactId) => {
  if (sendingCard) return;
  setSendingCard(true);

  try {
    // 1. Call the API to send the business card
    const response = await axios.post(`${config.apiBaseUrl}/api/contact/send-card/${contactId}`, {}, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    alert(response.data.message);

    // 2. Refetch all contacts to get the latest data, including updated `contactDetailsSent` flag
    const res = await axios.get('${config.apiBaseUrl}/api/contact', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    setContacts(res.data); // Update the contacts with fresh data from the backend

  } catch (error) {
    console.error('Failed to send business card:', error);
    alert('Failed to send business card.');
  } finally {
    setSendingCard(false);
  }
};
     
  const handleRetractCard = async (contactId) => {
    try {
      console.log(`DELETE URL: ${config.apiBaseUrl}/api/contact/retract-card/${contactId}`);
      console.log("Retracting contact with ID:", contactId);

      const response = await axios.delete(`${config.apiBaseUrl}/api/contact/retract-card/${contactId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      alert(response.data.message);

      // Update the contact details locally to reflect that the card has been retracted
      setContacts((prevContacts) =>
        prevContacts.map((contact) =>
          contact._id === contactId ? { ...contact, contactDetailsSent: false } : contact
        )
      );
    } catch (error) {
      console.error('Failed to retract business card:', error);
      alert('Failed to retract business card.');
    }
  };


  return (
  <div className="contacts-page">
      {isMobile ? (
        // Mobile Layout
    <div className="mobile-layout">
      <div className="header-container">
      <h2 className="contacts-title">Contacts</h2>

     {/* Using the CustomDropdown component */}
     <CustomDropdown filter={filter} handleFilterChange={handleFilterChange} />
      </div> 

      <div className="contacts-list">
        {error && <p className="error-message">{error}</p>}
        <div className="cards-container">
        {filteredContacts.length > 0 ? (
                filteredContacts.map((contact) => (

  <div
    key={contact._id}
    className={`business-card ${selectedContactId === contact._id && !hoveredContactId ? 'selected' : ''} 
    ${hoveredContactId === contact._id ? 'hovered' : ''}`}
    onClick={() => handleCardClick(contact._id)}
   onMouseEnter={() => handleCardHover(contact._id)}
   onMouseLeave={handleCardLeave}
  >
                <p><strong>{contact.name}</strong></p>
                <p>{contact.company}</p>
                {contact.role && <p>{contact.role}</p>}
                <p>{contact.email}</p>
                <p>{contact.phone}</p>
                {contact.linkedinURL && (
                  <p>
                    <a href={contact.linkedinURL} target="_blank" rel="noopener noreferrer">
                      LinkedIn Profile
                    </a>
                  </p>
                )}
                {contact.additionalURL && (
                  <p>
                    <a href={contact.additionalURL} target="_blank" rel="noopener noreferrer">
                      {contact.additionalURL.length > 16 ? `${contact.additionalURL.slice(0, 16)}...` : contact.additionalURL}
                    </a>
                  </p>
                )}

 {/* Flag icon on the right */}
 <span
                      className={`flag-icon ${flaggedContacts.includes(contact._id) ? 'flagged' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the card click
                        toggleFlag(contact._id);
                      }}
                    >
                      ⚑ {/* Unicode white flag */}
                    </span>


                    {isEditing ? (
  <>
    {/* In Edit mode, always show the Send and Retract buttons */}
    <button
      type="button"
      className="send-card-button"
      onClick={() => {
        const confirmSend = window.confirm(`Send business card to ${contact.name}?`);
        if (confirmSend) handleSendCard(contact._id);
      }}
      // disabled={contact.contactDetailsSent} // Disable if already sent in edit mode
    >
      ✉️{/* Send contact details */}
    </button>
    {/* removed retract button until can get working. Logic remain elsewhere but doesnt work.
    <button
      type="button"
      className="retract-card-button"
      onClick={() => {
        const confirmRetract = window.confirm(`Retract your business card from ${contact.name}?`);
        if (confirmRetract) handleRetractCard(contact._id);
      }}
    >
      ↩{/* Retract contact details 
    </button>
    */}
  </>
) : (
  <>
    {/* In Normal mode, only show the Send button if the card has not been sent */}
    {!contact.contactDetailsSent && !contact.inviteSentByEmail && (
      <button
        type="button"
        className="send-card-button"
        onClick={() => {
          const confirmSend = window.confirm(`Send business card to ${contact.name}?`);
          if (confirmSend) handleSendCard(contact._id);
        }}
        disabled={sendingCard} // Disable button while sending
      >
        ✉️{/* Send contact details */}
      </button>
    )}
  </>
)}


{/* Show delete option only for the highlighted card in Edit mode */}
{isEditing && selectedContactId === contact._id && (
  <div ref={confirmDeleteRef} className="confirm-delete-container">
    <p>Are you sure you want to delete {contact.name}?</p>
    <button
      type="button"
      className="confirm-delete-button"
      onClick={() => handleDelete(contact._id)}
    >
      Delete Contact
    </button>
    <button
      type="button"
      className="cancel-delete-button"
      onClick={cancelDelete}
    >
      Cancel
    </button>
  </div>
)}
                  </div>
                ))
              ) : (
                <p>No contacts found.</p>
              )}
            </div>


         
        {/*<div className="action-buttons">
          <button onClick={handleEdit} className="edit-button">Edit</button>*/}
          <div className="action-buttons" onClick={handleEdit}>
  <span className="contacts-edit-button">Edit</span>
        </div>
        </div>


<div className="map-container" onClick={handleMapClick} style={{ cursor: isMobile ? 'pointer' : 'default' }}>
  {selectedContactId && contacts.length > 0 ? (
    (() => {
      const selectedContact = contacts.find(contact => contact._id === selectedContactId);
      console.log('Selected Contact:', selectedContact);

      // Ensure that the location is valid and fallback to a default location (London) if it's not available
      const location = selectedContact?.lat && selectedContact?.lng
        ? [selectedContact.lat, selectedContact.lng]
        : [51.505, -0.09]; // Fallback to London if no location is available

      if (selectedContact) {
        return (
            <MapContainer center={mapCenter} zoom={mapZoom} className="mini-map"> {/* Directly use location */}
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={location}>
              <Popup>{selectedContact.name}'s Location</Popup>
            </Marker>

            {/* Dynamically change map view based on the selected contact */}
            <ChangeMapView center={mapCenter} zoom={mapZoom} /> {/* New component to handle dynamic map view changes */}
            <ResizeMapView /> {/* New component to handle map resizing and centering */}
          </MapContainer>
        );
      } else {
        return <p>Select a contact to view their location.</p>;
      }
    })()
  ) : (
    <p>Select a contact to view their location.</p>
  )}

  {/* Optional expand map link for desktop view */}
  {!isMobile && <Link to="/map" className="expand-map-button">Expand Map</Link>}


</div>
</div>
      ) : (
  // Placeholder for larger screen layout if needed later
  <></>
  )}
</div>
  );
};

export default ContactsPage;
